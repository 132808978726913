import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import DefaultLayout from '@/layouts/default';
import SEO from '@/components/layout/seo';
import { useForm } from 'react-hook-form';
import { submitForgotPassword } from '@/services/api';
import { Link } from 'gatsby';
import { FaArrowLeft } from 'react-icons/fa';
import Masthead from '@/components/layout/masthead';
import LoginContent from '@/components/LoginContent';
import IdmMastheadContent from '@/components/IdmMastheadContent';

const Page = () => {
  const {
    register: forgotPassword,
    getValues: getForgotPasswordValues,
  } = useForm();

  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const formChanged = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    let forgotPasswordData = getForgotPasswordValues();
    let res = await submitForgotPassword(forgotPasswordData);
    if (!res.error) {
      setError('A password reset has been sent to the email supplied.');
    } else {
      setError(res.message);
    }
    setSubmitting(false);
  };

  return (
    <DefaultLayout>
      <SEO title="Forgot Your Password" keywords={[]} />
      <Masthead>
        <IdmMastheadContent />
      </Masthead>
      <Container className="">
        <Row className="">
          <Col lg={7} className="mt-5 my-lg-5">
            <LoginContent />
          </Col>
          <Col lg={{ span: 4, offset: 1 }} className="mt-3 my-lg-5 shadow p-5">
            <h3>Password Reset</h3>
            {error && <div className="alert alert-warning">{error}</div>}
            <Form onSubmit={(e) => formChanged(e)}>
              <Form.Group controlId="emailAddress">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  ref={forgotPassword}
                  name="email"
                />
              </Form.Group>

              <Button variant="primary" className="cute-button" type="submit">
                {submitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Reset Password</span>
                )}
              </Button>
            </Form>
            <p className="mt-4">
              <Link to="/login/">
                <FaArrowLeft /> Return to Login
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </DefaultLayout>
  );
};

export default Page;
